html {
  background: #FFFFFF;
  box-sizing: border-box;
}
h1, h2, h3,body, p, li{
  font-family: 'Raleway', sans-serif;;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
:root {
  --text-primary-color: #FFFFFF;
  --heаding-primary-color: #FFFFFF;
  --bg-primary-color: #E3F3F9;
  --link-primary-color: #FFFFFF;
  --button-primary-color: #5FE0C1;
  --paragrapgh-color: #F7F7F7;
  --li-color: #FFFFFF;
  --li-circle-color: #5FE0C1;
  --bg-box: #F7F7F7;
}

body {
  font-size: 16px;
  line-height: 30px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  margin: 0px;
}

h1 {
    font-style: normal;
    font-weight: 200;
    font-size: 90px;
    line-height: 106px;
    text-transform: uppercase;
     color: var(--heаding-primary-color);
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--heаding-primary-color);
  padding: 25px 0;
}
p, li{
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--text-primary-color);
  padding: 20px 0;
}
p+p, li+li{
  padding-top: 0px;
}

a {
  text-decoration: underline;
  color: var(--link-primary-color);
}
ul{
  list-style: none;
}
@media only screen and (max-width: 1067px) {
  h1, .privacy h1{
    font-size: 32px!important;
    line-height: 38px!important;
  }
  h2, .privacy h2{
    font-size: 18px!important;
    line-height: 21px!important;
    text-align: center!important;
  }
  h3, .privacy h3{
    font-size: 16px!important;
    line-height: 19px!important;
    text-align: center!important;
  }
  p, .privacy p, .privacy li{
    font-size: 14px!important;
    line-height: 24px!important;
  }
}
/* General */

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
}
@media (min-width: 1300px){
  .container {
      min-width: 1350px;
  }
}
@media only screen and (max-width: 1025px) {
  .container {
      padding: 0 20px;
  }
}
.grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}
.text-white{
  color: white;
}

@media only screen and (max-width: 1067px){
  .container {
    padding-left: 25px!important;
    padding-right: 25px!important;
}
}

/* Header */
.site-header{
  padding: 50px 0;
}
@media only screen and (min-width: 1700px) {
  .site-header{
    padding: 70px 0 5px;
  }
}
/* Coming soon */

.bg-gradient *, .privacy .site-header{
  background: #1E1E1E;
}
.coming-soon{
  text-align: center;
}
.coming-soon .site-logo a {
  position: relative;
  display: inline-block;
  display: block;
  width: 350px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
  font-size: 0;
  background: url(../image/logo.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  z-index: 2;
}
@media only screen and (min-width: 1700px) {
  .coming-soon .site-logo a {
    height: 150px;
  }
}
@media only screen and (max-width: 1067px) {
  .coming-soon .site-header {
    padding: 0;
  }
  .privacy .site-header {
    padding-top: 30px;
  }
  .coming-soon .site-logo a, .privacy .site-logo a{
    width: 170px!important;
  }
}

.coming-soon .center:after{
  content: "";
  background-image: url(../image/bg-shape.svg);
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0;
}
.wrapper {
  overflow-x: hidden;
}
.coming-soon .center *, .privacy{
  background: transparent;
  z-index: 1;
}
.coming-soon .center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 15% 0;
  min-height: 900px;
}
.coming-soon .intro-box p{
    padding: 0 20%;
}

@media only screen and (min-width: 1700px) {
  .center {
    padding: 0;
  }
  .coming-soon .center:after{
    height: 85%;
  }
}
@media only screen and (max-width: 1067px) {
  .coming-soon .intro-box p{
    padding: 0 20px;
  }
  .coming-soon .center:after{
    background-position: bottom left;
    background-size: contain;
  }
  .coming-soon .center{
    min-height: 800px;
  }
}
@media only screen and (max-width: 290px) {
  .coming-soon .center{
    min-height: 1000px;
  }
  .coming-soon .site-logo a, .privacy .site-logo a{
    width: 120px!important;
  }
  .coming-soon .site-header {
    padding: 10px 0;
  }
}

/* Signup Form */
#mc_embed_signup {
  background: transparent;
  clear: left;
  margin: 30px 0;
  width: 100%;
}
.horizontal-form{
  display: flex;
  justify-content: center;
  max-width: 580px;
  margin: 0 auto;
  padding-left: 10px;
}
#mc_embed_signup .mc-field-group{
  width: 100%;
}
#mc_embed_signup input.email{
  height: 60px;
  font: 18px Raleway, sans-serif;
  line-height: 21px;
  background: rgba(255, 255, 255, 0.9);
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  border: none;
  padding-left: 10px;
  width: 108%;
}
#mc_embed_signup form{
  padding-left: 0;
}
#mc_embed_signup input.email:focus-visible {
  outline: -webkit-focus-ring-color none;
  border: 1px solid #5DDBD5;
}
#mc_embed_signup input.email::placeholder{
  font-family: Raleway;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #B3B3B3;
}
#mc_embed_signup .button{
  height: 60px;
  font-weight: 500;
  font: 18px Raleway, sans-serif;
  color: #151515;
  line-height: 21px;
  padding: 0 35px;
  background-image: linear-gradient(104.76deg, #5DDBD5 8.61%, #B7F72D 118.09%);
  border-radius: 36px;
  position: relative;
  right: 6px;
  z-index: 1;
}
#mc_embed_signup .button:hover{
  background-image: linear-gradient(104.76deg, #B7F72D 2%, #5DDBD5 82.40%);
}

#mc_embed_signup div#mce-responses{
    display: block;
    width: 100%;
    float: none;
    max-width: 570px;
    margin: 0 auto;
    padding-left: 10px;
}
#mc_embed_signup #mce-error-response{
  background: rgba(188, 188, 188, 0.9);
  box-shadow: 0px 4px 11px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
#mc_embed_signup #mce-success-response{
  background: #5ddbd5bf;
  color: inherit;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
#mc_embed_signup div.response{
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  color: #454545;
  padding: 10px;
}
@media only screen and (max-width: 869px) {
  #mc_embed_signup{
    margin: 15px 0;
  }
  .horizontal-form{
    display: block;
    padding-left: 0;
  }
  .mce-responses{
    padding-left: 0px;
  }
  #mc_embed_signup div.response{
    padding: 5px;
  }
  #mc_embed_signup form{
    padding: 0 20px;
  }
  #mc_embed_signup .mc-field-group{
    width: 100%;
    padding: 0;
  }
  #mc_embed_signup input.email{
    border-radius: 36px;
    text-align: center;
    width: 100%;
  }
  #mc_embed_signup .foot {
    display: inline;
  }
  #mc_embed_signup .button{
    width: 100%;
    right: 0;
    bottom: 0px;
  }
  #mc_embed_signup div#mce-responses{
    padding: 0;
  }
}

/* Privacy */
.privacy{
  background: white;
  padding-bottom: 50px;
}
.privacy h1{
  font-weight: bold;
  font-size: 70px;
  line-height: 82px;
  margin: 60px 0 40px;
  text-transform: initial;
}
.privacy h2{
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #161616;
  margin-top: 50px;
}
.privacy h3{
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #161616;
  padding: 25px 0;
}
@media only screen and (max-width: 869px) {
  .privacy h2:not(:first-child){
    margin-top: 0px;
  }
}
.privacy p, .privacy li{
  text-align: left;
}
.privacy a{
  color: inherit;
}
.privacy li{
  padding-bottom: 10px;
}
.privacy ul{
  list-style: disc;
  padding-left: 2em;
}
.privacy .site-header{
  padding-bottom: 70px;
}
.privacy .site-logo a {
  position: relative;
  display: inline-block;
  display: block;
  width: 230px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  font-size: 0;
  background: url(../image/logo.svg) no-repeat 0 0 / contain;
  z-index: 2;
}
.privacy .bg-white p, .privacy li{
  color: #161616;
}


